
import { IonRow, IonCol, IonGrid, IonButton, IonLoading, 
IonIcon, IonAlert, IonLabel } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { trashOutline, createOutline, fastFood } from 'ionicons/icons';
import ModalAutocomplete from "../../../General/Components/ModalAutocomplete";
import styles from "../Css/DetailOrder.module.scss";
import ModalDetailProduct from './NewOrEditDetailProduct';
import { getListProducts, getDetails } from '../Service';

const NewOrEditOrder = ({order, client}) => {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [detail, setDetail] = useState([]);
    const [showAlertDeleteProduct, setShowAlertDeleteProduct] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpenModalProduct, setIsOpenModalProduct] = useState(false);
    const [listProducts, setListProducts] = useState([]);
    const [viewListProducts, setViewListProducts] = useState(false);

    useEffect(() => {
        searchArticlesPos();
        if(order.code){
            searchDetails();
        }
        // eslint-disable-next-line
    }, [order]);

    function searchArticlesPos(page = 1) {
        const productsPos = JSON.parse(localStorage.getItem('productsPos'));
        if(productsPos){
            setListProducts(productsPos);
        } else {
            setLoading(true);
            getListProducts(page).then((response) => {
                setLoading(false);
                if (response.status) {
                    setListProducts(response.data.data);
                    localStorage.setItem('productsPos', JSON.stringify(response.data.data));
                }
            });
        }
    }

    function searchDetails(){
        setLoading(true);
        getDetails(order.code).then((response) => {
            setLoading(false);
            if (response.status) {
                setDetails(response.data);
            }
        });
    }

    const printBody = () => {
        if (details.length > 0) {
            return (
                <div className={styles.scrollContainer}>
                    {details.map((item, key) => (
                        <IonRow key={key} className={styles.detailItems}>
                            <IonCol size="8">
                                {item.description}
                            </IonCol>
                            <IonCol size="2">
                                <IonButton size="small" onClick={editProduct}>
                                    <IonIcon icon={createOutline} />
                                </IonButton>
                            </IonCol>
                            <IonCol size="2">
                                <IonButton size="small" onClick={() => alertOpen(item)}>
                                    <IonIcon icon={trashOutline} />
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    ))}
                </div>
            );
        }
    }

    function editProduct() {
    }

    function deleteProduct() {
        console.log(selectedItem);
    }

    function searchProduct(){
        setViewListProducts(!viewListProducts);
    }

    function addProduct(e) {
        console.log(e);
        if(e){
            console.log('entrooo');
        }
        setIsOpenModalProduct(!isOpenModalProduct);
    }

    function alertOpen(item) {
        setShowAlertDeleteProduct(true);
        setSelectedItem(item);
    }

    function addDetail(data){
        setViewListProducts(!viewListProducts);
        setDetail(data[0]);
        addProduct();
    }

    return (
        <div >
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonRow fixed={true}>
                <IonCol size="10" className={styles.information}>
                    <IonLabel>Nombre:</IonLabel><b>{client.name}</b><br></br>
                    <IonLabel>Fecha:</IonLabel><b>{order.date}</b>
                </IonCol>
                <IonCol size="2">
                    <IonButton size="small" onClick={() => searchProduct()}>
                        <IonIcon icon={fastFood} />
                    </IonButton>
                </IonCol>
            </IonRow>
            <div className={styles.detailProducts}>
                <fieldset>
                    <legend>Detalle Productos</legend>
                    <IonGrid>
                        {printBody()}
                    </IonGrid>
                </fieldset>
            </div>
            <IonAlert
                isOpen={showAlertDeleteProduct}
                onDidDismiss={() => setShowAlertDeleteProduct(false)}
                cssClass="my-custom-class"
                header={"¿Está seguro que desea eliminar el producto?"}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                    {
                        text: "Eliminar",
                        handler: () => {
                            deleteProduct();
                        }
                    }
                ]} />
            <ModalDetailProduct 
                open={isOpenModalProduct} 
                fn={addProduct} 
                item = {detail}
                order = {order}
            />
            <ModalAutocomplete 
                tittle = {'Productos'}
                open = {viewListProducts}
                items = {listProducts}
                cancelChanges = {searchProduct}
                confirmChanges = {addDetail}
                value = {'code'}
                text = {'description'}
                returnObject = {true}
            />
        </div>
    );
};

export default NewOrEditOrder;