import React, { useState, useEffect } from 'react';
import styles from "../Css/ModalAutocomplete.module.scss";
import { IonModal, IonHeader, IonToolbar,  IonTitle, IonButtons, IonButton,
IonLabel, IonContent, IonCard, IonSearchbar, IonItem, IonCheckbox, 
IonList, useIonToast} from '@ionic/react';
import { closeCircle } from 'ionicons/icons';

const ModalAutocomplete = ({
    open,
    cancelChanges,
    confirmChanges,
    tittle,
    items = [],
    value = 'id',
    text = 'description',
    returnObject = false,
    multiple = false
}) => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ fields, setFields ] =  useState([]);
	const [ results, setResults ] = useState([]);
    const [ itemsSelected, setItemsSelected] = useState([]);
    const [ itemsChecked, setItemsChecked] = useState([]);
    const [present] = useIonToast();

    useEffect(() => {
        setIsOpen(open);
        setFields(items);
        setResults(items);
        setItemsSelected([]);
        setItemsChecked([]);
        // eslint-disable-next-line
    }, [open]);

    const search = (e) => {
		const searchTerm = e.currentTarget.value;
		if (searchTerm !== "") {
			const searchTermLower = searchTerm.toLowerCase();
			const newResults = fields.filter(e => e.description.toLowerCase().includes(searchTermLower));
			setResults(newResults);
		} else {
			setResults(fields);
		}
	}

    function checkboxChange(e, object) {
        let data = itemsSelected;
        if (multiple) {
            if (e.detail.checked) {
                if (returnObject) {
                    data.push(object);
                } else {
                    data.push(object[value]);
                }
            } else {
                setItemsSelected(deleteSelected(object[value]));
            }
        } else {
            if (e.detail.checked) {
                data.push(object);
                setItemsChecked(object[value]); 
            }
        }
    }

    function deleteSelected(id) {
        let filtered;
        if (returnObject) {
            filtered = itemsSelected.filter(function (item) {
                return item[value] !== id;
            });
        } else {
            filtered = itemsSelected.filter(function (item) {
                return item !== id;
            });
        }
        return filtered;
    }

    function accept(){
        if(itemsSelected.length > 0){
            confirmChanges(itemsSelected);
        } else {
            present({
                message: 'Debe seleccionar mínimo un ítem para continuar',
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            });
        }
    }

    return (
        <IonModal isOpen={isOpen}>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={cancelChanges}>Cancelar</IonButton>
                    </IonButtons>
                    <IonTitle>{tittle}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={accept}>Aceptar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.content}>
                <div className={styles.center}>
                    <IonCard>
                        <IonSearchbar onIonInput={e => search(e)} placeholder="Buscar..." icon={search} />
                        <div className={styles.Row} >
                            <IonList>
                                {results.map((result) => {
                                    return (
                                        <IonItem key={result[value]}>
                                            <IonLabel>{result[text]}</IonLabel>
                                            {multiple ? (
                                                <IonCheckbox
                                                    value={result[value]}
                                                    onIonChange={(e) => checkboxChange(e, result)}
                                                />
                                            ) : (
                                                <IonCheckbox
                                                    value={result[value]}
                                                    onIonChange={(e) => checkboxChange(e, result)}
                                                    checked={itemsChecked.includes(result[value])}
                                                />
                                            )}
                                        </IonItem>
                                    );
                                })}
                            </IonList>
                        </div>
                    </IonCard>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default ModalAutocomplete;
