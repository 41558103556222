import React, { useState, useEffect } from 'react';
import styles from "../Css/ModalProduct.module.scss";
import {
  IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton,
  IonContent, IonCard, IonCardSubtitle, IonList, IonRow, IonCol, IonLoading,
  IonInput, IonLabel, IonIcon, IonItem, useIonToast
} from '@ionic/react';
import { saveOutline, closeCircle } from 'ionicons/icons';
import { createOrderDetail } from '../Service';

const ModalDetailProduct = ({ open, fn, order, item, type}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [present] = useIonToast();

  useEffect(() => {
    setIsOpen(open);
    setProduct(item);
    setQuantity('');
     // eslint-disable-next-line
  }, [open]);

  const handleQuantityChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/[^0-9]/g, '');
    setQuantity(numericInput);
  };

  const printBody = () => {
    return (
      <div className="container">
        <IonRow>
          <IonCol size='12'>
            <IonLabel color="dark">Producto</IonLabel> <br></br> {product.code} - {product.description}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            <IonItem>
              <IonLabel color="dark" position="stacked">Ingrese cantidad</IonLabel>
              <IonInput 
                type="number"
                placeholder="Cantidad"
                value={quantity}
                onInput={handleQuantityChange}
              />
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow className={styles.customCenter}>
          <IonButton size="small" onClick={() => saveDetail()}>
            <IonIcon icon={saveOutline} /> Guardar
          </IonButton>
        </IonRow>
      </div>
    );
  }

  function saveDetail() {
    if(validateProduct()){
      let product = {
        quantity
      }
      product.product = 1;
      product.orderHeader = order.code;
      setLoading(true);
      createOrderDetail(product).then((response) => {
          setLoading(false);
          if (response.status) {
            fn(response.data);
          } else {
              messageError(response.messages);
          }
      });
    }
  }

  function validateProduct(){
    let validate = true;
    let errors = [];
    if(quantity === ''){
        errors.push('Debe ingresar la cantidad del producto');
    }
    
    if (errors.length > 0) {
        validate = false;
        messageError(errors);
    }
    return validate;
}

  function messageError(messages) {
    messages.map((message) => (
        present({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'secondary',
            icon: closeCircle
        })
    ));
}

  return (
    <IonModal isOpen={isOpen}>
      <IonLoading isOpen={loading} message="Cargando..." />
      <IonHeader>
        <IonToolbar>
          <IonTitle></IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => fn('')}>Cerrar</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={styles.content}>
        <div className={styles.center}>
          <IonCard>
            <IonCardSubtitle>Informacion del Producto</IonCardSubtitle>
            <IonList>
              {printBody()}
            </IonList>
          </IonCard>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ModalDetailProduct;
