import React, { useEffect, useState } from 'react';
import {
    IonButton, IonContent, IonHeader, IonInput, IonItem, IonLoading,
    IonLabel, IonModal, IonTitle, IonToolbar, IonButtons, IonCard, 
    useIonToast, IonSelect, IonSelectOption
} from '@ionic/react';
import { closeCircle } from "ionicons/icons";
import styles from "../Css/ModalComponent.module.scss";
import {createClient} from "../Service";

const ClientRegistrationModal = ({ open, onClose, returnClient, clientId }) => {
    const [identify, setIdentify] = useState('');
    const [typeIdentify, setTypeIdentify] = useState('1');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [addres, setAddres] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [present] = useIonToast();

    useEffect(() => {
        setIsOpen(open);
        setIdentify(clientId);
        // eslint-disable-next-line
    }, [open]);

    const handleSave = () => {
        if(validateClient()){
            const client = {
                typeIdentify,
                identify,
                name,
                email,
                phone,
                addres
            };
            setLoading(true);
            createClient(client).then((response) => {
                setLoading(false);
                if (response.status) {
                    clearValues();
                    returnClient(client);
                    onClose();
                } else {
                    messageError(response.messages);
                }
            });
        }
    };

    function clearValues(){
        setIdentify('');
        setName('');
        setEmail('');
        setPhone('');
    }

    function validateClient(){
        let validate = true;
        let errors = [];
        if(addres === ''){
            errors.push('Ingrese la dirección del cliente');
        }
        if(phone === ''){
            errors.push('Ingrese el teléfono del cliente');
        }
        if(email === ''){
            errors.push('Ingrese el email del cliente');
        }
        
        if(name === ''){
            errors.push('Ingrese el nombre del cliente');
        }
        if(identify === ''){
            errors.push('Ingrese el número de identificación del cliente');
        }
        
        if (errors.length > 0) {
            validate = false;
            messageError(errors);
        }
        return validate;
    }

    function messageError(messages) {
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            })
        ));
    }

    return (
        <IonModal isOpen={isOpen}>
        <IonLoading isOpen={loading} message="Cargando..." />
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Registrar Cliente</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => onClose()}>
                            Cerrar
                            <ion-icon slot="start" icon={closeCircle}></ion-icon>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={styles.content}>
                <div className={styles.center}>
                    <IonCard>
                        <IonItem>
                            <IonLabel position="floating">Tipo Identificación</IonLabel>
                            <IonSelect 
                                placeholder="Tipo Identificación" 
                                value={typeIdentify}
                                onIonChange={(e) => setTypeIdentify(e.detail.value)}
                            >
                            <IonSelectOption value="1">Cédula</IonSelectOption>
                            <IonSelectOption value="2">Pasaporte</IonSelectOption>
                            <IonSelectOption value="3">RUC</IonSelectOption>
                            </IonSelect>
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Número de Identificación</IonLabel>
                            <IonInput
                                type="text"
                                value={identify}
                                onIonChange={(e) => setIdentify(e.detail.value)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Nombre Completo</IonLabel>
                            <IonInput
                                type="text"
                                value={name}
                                onIonChange={(e) => setName(e.detail.value)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Correo Electrónico</IonLabel>
                            <IonInput
                                type="email"
                                value={email}
                                onIonChange={(e) => setEmail(e.detail.value)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Número de Teléfono</IonLabel>
                            <IonInput
                                type="tel"
                                value={phone}
                                onIonChange={(e) => setPhone(e.detail.value)}
                            />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="floating">Dirección</IonLabel>
                            <IonInput
                                type="text"
                                value={addres}
                                onIonChange={(e) => setAddres(e.detail.value)}
                            />
                        </IonItem>
                        <IonButton expand="full" onClick={handleSave}>
                            Guardar
                        </IonButton>
                    </IonCard>
                </div>
            </IonContent>
        </IonModal>
    );
};

export default ClientRegistrationModal;
