import React, { useEffect, useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  useIonToast,
  IonLoading,
} from "@ionic/react";
import { search, list, closeCircle } from "ionicons/icons";
import CustomPaginatorShort from "../../../../General/Components/CustomPaginatorShort";
import CustomSelector from "../../../../General/Components/CustomSelector";
import { getAreas } from "../../Services";

const SearchDetail = ({
  page,
  setPage,
  total,
  to,
  fnPaginate,
  fnSearch,
  fnSearchAll,
  fnFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const [areaSelect, setAreaSelected] = useState('GENERAL');
  const [areas, setAreas] = useState([]);
  const [present] = useIonToast();

  useEffect(() => {
    initialData();
  // eslint-disable-next-line
  }, []);
  
  const initialData = () => {
    const areaList = JSON.parse(sessionStorage.getItem('areaListPT'));
    if(areaList){
      setAreas(areaList);
    }else {
      setLoading(true);
      getAreas().then((response) => {
        setLoading(false);
        if(response.status){
          setAreas(response.data);
          sessionStorage.setItem('areaListPT', JSON.stringify(response.data));
        }else {
          response.messages.map((message)=> (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            })
          ));
        }
      });
    }
  };

  const searchParameter = () => {
    fnSearch(
      document.getElementById("name").value,
      document.getElementById("code").value,
      areaSelect
    );
  };

  const searchAll = () => {
    document.getElementById("name").value = "";
    document.getElementById("code").value = "";
    fnSearchAll();
  };
  return (
    <IonRow className="d-flex align-items-center">
      <IonLoading isOpen={loading} message="Cargando..." />
      <IonCol size="6" size-md="6">
        <IonItem>
          <IonInput
            onKeyUp={(e) => fnFilter(e, "name")}
            name="name"
            id="name"
            type="text"
            required
            placeholder="Nombre Artículo"
          ></IonInput>
        </IonItem>
      </IonCol>
      <IonCol size="6" size-md="6">
        <IonItem>
          <IonInput
            onKeyUp={(e) => fnFilter(e, "code")}
            name="code"
            id="code"
            type="text"
            required
            placeholder="Código Artículo"
          ></IonInput>
        </IonItem>
      </IonCol>
      <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
      <IonCol  size="6">
        <CustomSelector 
          tittle={"Area"}
          data={areas}
          identifier={'descripcion'}
          description={'descripcion'}
          value={areaSelect}
          handleChange={setAreaSelected}
          returnObject={false}
          legend={true}
        />
      </IonCol>
      &nbsp;  &nbsp;  &nbsp; 
      <IonCol  size="2">
        <CustomPaginatorShort pags={total} to={to} actualPage={page} fn={fnPaginate} setPage={setPage} />
      </IonCol>
      <div style={{display:'flex'}}>
      <IonCol  size="2">
       <IonButton onClick={searchParameter}>
         <IonIcon icon={search} />
       </IonButton>
     </IonCol>
     &nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
     <IonCol  size="2">
       <IonButton onClick={searchAll}>
         <IonIcon icon={list} />
       </IonButton>
     </IonCol>
      </div>
      </div>
    </IonRow>
  );
};

export default SearchDetail;








