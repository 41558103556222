
import {
    IonRow, IonCol, IonGrid, IonButton, IonLabel,
    IonIcon, IonInput, IonItem, IonLoading, useIonToast
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import styles from "../Css/NewOrEditOrder.module.scss";
import DetailOrder from "./DetailOrder";
import { search, person, add, closeCircle } from 'ionicons/icons';
import { getClient, createOrderHeader, putOrderHeader } from '../Service';
import NewClient from './NewClient';


const NewOrEditOrder = ({ type, order, returnOrderHeader }) => {
    const [status, setStatus] = useState(type);
    const [loading, setLoading] = useState(false);
    const [isOpenNewClient, setIsOpenNewClient] = useState(false);
    const [client, setClient] = useState({ identify: '9999999999999', name: 'consumidor final'});
    const [orderHeader, setOrderHeader] = useState([]);
    const [identify, setIdentify] = useState('');
    const [present] = useIonToast();

    useEffect(() => {
        if(type === 1 || type === 2){
            if(order.idClient !== '9999999999999'){
                //ACA VA LA CONSULTA DEL CLIENTE
                console.log('entro2');
            } else {
                defaultClient();
            }
            setOrderHeader(order);
        }
        // eslint-disable-next-line
    }, [type]);

    function searchClient() {
        setLoading(true);
        getClient(identify).then((response) => {
            setLoading(false);
            if (response.status) {
                setClient(response.data[0]);
            } else {
                openModal();
            }
        });
    }

    function defaultClient(){
        const client = {
            identify: '9999999999999',
            name: 'consumidor final'
        }
        setClient(client);
        setIdentify(client.identify);
        if(status === 1 && identify !== client.identify && identify !== ''){
            updateOrderHeader(order.code, client.identify);
        }
    }

    function messageError(messages) {
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            })
        ));
    }

    function openModal() {
        setIsOpenNewClient(!isOpenNewClient);
    }

    function saveOrderHeader() {
        setLoading(true);
        createOrderHeader(client.identify).then((response) => {
            setLoading(false);
            if (response.status) {
                setStatus(1);
                setOrderHeader(response.data);
                returnOrderHeader(response.data);
            } else {
                messageError(response.messages);
            }
        });
    }

    const onlyNumber = (event) => {
        const input = event.target.value;
        const identify = input.replace(/[^0-9]/g, '');
        setIdentify(identify);
    };

    const updateClient = (client) => {
        setClient(client);
        setIdentify(client.identify)
        if(status === 1 && identify !== client.identify){
            updateOrderHeader(order.code, client.identify);
        }
    }

    function updateOrderHeader(code, idClient) {
        setLoading(true);
        putOrderHeader(code, idClient).then((response) => {
            setLoading(false);
            if (!response.status) {
                messageError(response.messages);
            }
        });
    }


    return (
        <div className={styles.content}>
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonGrid>
                <IonRow fixed={true}>
                    <IonCol size="8">
                        <IonItem>
                            <IonLabel position="floating">Documento Identificación</IonLabel>
                            <IonInput
                                value={identify}
                                onInput={onlyNumber}
                                placeholder={client.identify}
                            />
                        </IonItem>
                    </IonCol>
                    <IonCol size="2">
                        <IonButton size="small" onClick={searchClient}>
                            <IonIcon icon={search} />
                        </IonButton>
                    </IonCol>
                    <IonCol size="2">
                        <IonButton size="small" onClick={defaultClient}>
                            <IonIcon icon={person} />
                        </IonButton>
                    </IonCol>
                    {(status === 0 && client.hasOwnProperty('identify'))  && (
                        <IonCol size="12">
                            <center>
                                <IonButton onClick={() => saveOrderHeader()}>
                                    Crear Orden
                                    <IonIcon icon={add} slot="end"></IonIcon>
                                </IonButton>
                            </center>
                        </IonCol>
                    )}
                </IonRow>
            </IonGrid>
            {status === 1 && (
                <DetailOrder
                    order={orderHeader}
                    client={client}
                />
            )}
            <NewClient
                open={isOpenNewClient}
                onClose={openModal}
                returnClient={updateClient}
                clientId = {identify}
            />
        </div>
    );
};

export default NewOrEditOrder;