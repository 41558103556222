import { IonRow, IonCol, IonGrid, IonButton, IonIcon } from "@ionic/react";
import React from "react";
import { addCircle, eyeOutline, create } from 'ionicons/icons';
import styles from "../Css/List.module.scss";
import CustomPaginator from "../../../General/Components/CustomPaginator";

const List = ({ newOrEditOrder, headers, details, total, forPage, viewDetail, listOrders, actualPage }) => {
    const printHeader = () => {
        const colHeader = headers.map((header) => (
            <IonCol key={header.value} className={styles.header}>
                <center>{header.text}</center>
            </IonCol>
        ));
        return colHeader;
    };

    const printBody = () => {
        const rowBody = details.map((item, key) => (
            <IonRow key={key} className={styles.items} fixed={true}>
                {headers.map(header => {
                    if (header.value === 'actions') {
                        if (item.status === '1') {
                            return (
                                <IonCol key={header.value} className={styles.col}>
                                    <center>
                                        <IonButton onClick={() => viewDetail(1, item)}>
                                            <IonIcon icon={create}></IonIcon>
                                        </IonButton>
                                    </center>
                                </IonCol>
                            );
                        } else {
                            return (
                                <IonCol key={header.value} className={styles.col}>
                                    <center>
                                        <IonButton onClick={() => viewDetail(2, item)}>
                                            <IonIcon icon={eyeOutline}></IonIcon>
                                        </IonButton>
                                    </center>
                                </IonCol>
                            );
                        }
                    } else {
                        return (
                            <IonCol key={header.value} className={styles.col}>
                                <center>{item[header.value]}</center>
                            </IonCol>
                        );
                    }
                })}
            </IonRow>
        ));

        return rowBody;
    }

    return (
        <div className={styles.content}>
            <IonGrid>
                <IonRow fixed={true}>
                    <IonCol size="12" align='right'>
                        <IonButton onClick={newOrEditOrder} size="small">
                            Nueva Orden
                            <IonIcon slot="end" icon={addCircle} />
                        </IonButton>
                    </IonCol>
                </IonRow>
                <fieldset >
                    <legend>Ordenes</legend>
                    <IonCol size="2">
                        <CustomPaginator tittle={'Pagina'} pags={total} to={forPage} actualPage={actualPage} fn={listOrders} />
                    </IonCol>
                    <IonRow fixed={true}>
                        {printHeader()}
                    </IonRow>
                    <div className={styles.Row} >
                        <IonGrid>
                            {printBody()}
                        </IonGrid>
                    </div>
                </fieldset>
            </IonGrid>
        </div>
    );
};

export default List;