import { getPhysicTakeDetails, updateAmountPhysicalTake } from '../../Services';
import { IonRow, IonCol, IonList, IonInput, IonLoading, useIonToast } from "@ionic/react";
import React, { useEffect, useState } from "react";
import styles from "../../Css/List.module.scss";
import { checkmarkCircleOutline, closeCircle } from 'ionicons/icons';


const List = ({ page, name, code, physicTakeHeaderCode, look, fn, area }) => {
  const [headers, setHeader] = useState([]);
  const [data, setBody] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const [present] = useIonToast();

  useEffect(() => {
    setLoading(true);
    const data = {
      physicTakeHeaderCode: physicTakeHeaderCode,
      nameArtcicle: name,
      codeArticle: code,
      page: page,
      area: area,
      limit: 1000,
      isPaginate: 1
    };
    getPhysicTakeDetails(data).then((response) => {
      if (response.status) {
        setHeader(response.headers);
        if (response.data.data) {
          setBody(response.data.data);
          setResults(response.data.data);
          fn(response.data.total, response.data.to);
        }
      } else {
        response.messages.map((message) => (
          present({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: 'danger',
            icon: closeCircle
          })
        ));
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [look]);

  useEffect(() => {
    if (name !== "") {
      const searchTermLower = name.toLowerCase();
      const newResults = data.filter(e => e.name.toLowerCase().includes(searchTermLower));
      setResults(newResults);
    } else {
      setResults(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (code !== "") {
      const searchTermLower = code.toLowerCase();
      const newResults = data.filter(e => e.articleCode.toLowerCase().includes(searchTermLower));
      setResults(newResults);
    } else {
      setResults(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const updateAmount = (item) => {
    const value = document.getElementById(item.articleCode + '-' + item.warehouseArticleCode).value;
    if (value !== "" && value !== undefined) {
      const data = {
        type: "basic",
        id_take_phisycal: physicTakeHeaderCode,
        id_artcicle: item.warehouseArticleCode,
        area_code: (item.areaCode) ? item.areaCode : 99,
        physical_quantity: value
      };
      setLoading(true);
      updateAmountPhysicalTake(data).then((response) => {
        setLoading(false);
        let icon = (response.status) ? checkmarkCircleOutline : closeCircle;
        let color = (response.status) ? 'success' : 'danger';
        response.messages.map((message) => (
          present({
            message: message,
            duration: 3000,
            position: 'bottom',
            color: color,
            icon: icon
          })
        ));
      });
    }
  }

  const onlyNumber = (e, item) => {
    let number = e.currentTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    let decimals = number.split('.');
    if (decimals.length > 1) {
      number = decimals[0] + '.' + decimals[1].substr(0, 3);
    }
    document.getElementById(item.articleCode + '-' + item.warehouseArticleCode).value = number;
  }



  const printBody = () => {
    const rowBody = results.map((item, key) => (
      <IonRow key={key} className={styles.employeeItem}>
        {headers.map(header => {
          if (header.value === 'actions') {
            return (
              <IonCol size='6' key={header.value}>
                <h2><b>{header.text}</b></h2>
                <IonInput
                  label="Outline input"
                  labelPlacement="floating"
                  fill="outline"
                  id={item.articleCode + '-' + item.warehouseArticleCode}
                  placeholder={item.physicQuantity}
                  type="number"
                  onBlur={() => updateAmount(item)}
                  onInput={(e) => onlyNumber(e, item)}
                />
              </IonCol>
            );
          } else {
            return (
              <IonCol size='6' id={item.warehouseArticleCode + '-' + header.value} key={header.value}>
                <h2><b>{header.text}</b></h2> {item[header.value]}
              </IonCol>
            );
          }
        })}
      </IonRow>
    ));

    return rowBody;
  }

  return (
    <div className={styles.content}>
      <IonLoading isOpen={loading} message="Cargando..." />
      <IonList>
        {printBody()}
      </IonList>
    </div>
  );
};

export default List;
