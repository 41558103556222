import { returnResponse, formatData } from '../../Helper/Helpers';
import HttpClient from '../../services/HttpCilent';
import { headersOrdes, formatOrders, formatClient, formatProducts,
formatDetailsOrder } from './Data/Formats';
import { formatClientBack, formatOrderDetailBack } from './Data/FormatsBack';

export const getListOrders = (page) => {
    return new Promise((resolve) => {
        HttpClient.get('/api/pos/orderHeaders/getByIdRestaurant?page='+page
        ).then(response => {
            resolve(returnResponse(response, formatOrders(), headersOrdes(), true));
        })
    })
}

export const getClient = (identify) => {
    return new Promise((resolve) => {
        HttpClient.get(
            '/api/bagControlHeaders/getByStoreCodeDateFreightTypeAndHeaderRoadmap?'
            +'id_header_roadmap='+identify
            +'&store_code=K007'
            +'&freight_number='+identify
        ).then(response => {
            resolve(returnResponse(response, formatClient()));
        })
    })
}

export const createClient = (data) => {
    let client = formatData(data, formatClientBack());
    return new Promise((resolve) => {
        HttpClient.post('/api/pos/clientPos?type=basic'
            ,client
        ).then(response => {
            resolve(returnResponse(response, formatClient()));
        })
    })
}

export const getListProducts = () => {
    return new Promise((resolve) => {
        HttpClient.get('api/physicTakeDetails/getAll?id_physic_take=D73F858E-E8F1-449A-9361-43006B7648AE&article_name=&article_code=&area=GENERAL&page=1&limit=10&is_paginated=1'
        ).then(response => {
            resolve(returnResponse(response, formatProducts(), [], true));
        })
    })
}

export const createOrderHeader = (identify) => {
    const orderHeader = {
        id_client: identify,
        types: "SALON" 
    }
    return new Promise((resolve) => {
        HttpClient.post('/api/pos/orderHeaders'
            ,orderHeader
        ).then(response => {
            resolve(returnResponse(response, formatOrders()));
        })
    })
}

export const getDetails = (idOrderHeader) => {
    return new Promise((resolve) => {
        HttpClient.get('api/pos/orderDetails/getByOrderHeader?id='+idOrderHeader
        ).then(response => {
            resolve(returnResponse(response, formatDetailsOrder(), []));
        })
    })
}

export const createOrderDetail = (data) => {
    let detailOrder = formatData(data, formatOrderDetailBack());
    return new Promise((resolve) => {
        HttpClient.post('/api/pos/orderDetails'
            ,detailOrder
        ).then(response => {
            resolve(returnResponse(response, formatDetailsOrder()));
        })
    })
}

export const putOrderHeader = (code, idClient) => {
    let orderHeader = {id_client: idClient};
    return new Promise((resolve) => {
        HttpClient.put('/api/pos/orderHeaders/'+code
            ,orderHeader
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}