export const headersOrdes = () => {
    return [
        { text: 'Ordenes', value: 'code'},
        { text: 'Opciones', value: 'actions'}
    ];
}

export const formatOrders = () => {
    return [
        { rename: 'code', name: 'idOrderHeader'},
        { rename: 'description', name: 'name'},
        { rename: 'status', name: 'status_headerOrde'},
        { rename: 'idClient', name: 'idClient'},
        { rename: 'types', name: 'types'},
        { rename: 'date', name: 'date'}
    ];
}

export const formatClient = () => {
    return [
        { rename: 'identify', name: 'freightNumber'},
        { rename: 'name', name: 'material_name'},
        { rename: 'email', name: 'headlock'},
        { rename: 'phone', name: 'temperature'},
        { rename: 'addres', name: 'addres'},
        { rename: 'typeIdentify', name: 'type_identify'}
    ];
}

export const formatProducts = () => {
    return [
        { rename: 'code', name: 'articleCode'},
        { rename: 'description', name: 'name'},
        { rename: 'status', name: 'physicQuantity'}
    ];
}

export const formatDetailsOrder = () => {
    return [
        { rename: 'detailId', name: 'id'},
        { rename: 'description', name: 'created_at'},
        { rename: 'product', name: 'idProduct'},
        { rename: 'quantity', name: 'quantity'}
    ];
}
