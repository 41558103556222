export function formatData(data, format) {
    let response = [];
    if(data.length){
        data.forEach((element) => {
            let item = {};
            format.map((header) => (
                item[header['rename']] = element[header['name']]
            ));
            response.push(item);
        })
    } else {
        response = {};
        format.map((header) => (
            response[header['rename']] = data[header['name']]
        ));
    }

    return response;
}

const  formatDataPaginated = (data, format) => {
    let response = {};
    let dataFormat = [];
    data.data.forEach((element) => {
        let item = {};
        format.map((header) => (
            item[header['rename']] = element[header['name']]
        ));
        dataFormat.push(item);
    });

    response.data = dataFormat;
    response.total = data.total;
    response.forPage = data.per_page;
    return response;
}

export function returnResponse(data, format = [], headers = [], isPaginated = false) {
    let response = {
        status: data.data.response, 
        data: [],
        headers: headers,
        messages: []
    }

    if(data.data.response && data.data.data){
        if(isPaginated){
            response.data = formatDataPaginated(data.data.data, format);
        } else {
            response.data = formatData(data.data.data, format);
        }
    } else {
        response.messages = (data.data.messages.length) ? data.data.messages : [data.data.alert];
    }

    return response;
}