import React, { useEffect, useState } from "react";
import { IonContent, IonCard, IonCardContent } from "@ionic/react";
import styles from "./Css/PhysicalTake.module.scss";
import CustomHeader from '../../General/Components/CustomHeader';
import BackButton from '../../General/Components/BackButton';
import PhysicalTakeHeader from './Components/PhysicalTakeHeader';
import PhysicalTakeDetail from './Components/Detail/PhysicalTakeDetail';
import { helpContent } from './Data/Data';

function PhysicalTake() {
    const [name, setName] = useState('Toma Física');
    const [state, setState] = useState('header');
    const [charge, setCharge] = useState(true);
    const [physicTakeHeaderCode, setPhysicTakeHeaderCode] = useState('');

    useEffect(() => {
        sessionStorage.removeItem("areaListPT");
    }, []);

    const render = (e, name, physicTakeHeaderCode, charge) => {
        setName(name);
        setPhysicTakeHeaderCode(physicTakeHeaderCode);
        setCharge(charge);
        setState(e);
    }

    function backView(view){
        render(view, 'Toma Física', '', false);
    }

    return (
    <>
    <CustomHeader name={name} content={helpContent()}/>
    <IonContent scrollY={false}>
        <div className={styles.center}>
        <IonCard className={styles.ionCard}>
            <IonCardContent>
            {state === 'header' && (
                <PhysicalTakeHeader charge={charge} fn={render}/>
            )}
            {state === 'detail' && (
                <PhysicalTakeDetail physicTakeHeaderCode={physicTakeHeaderCode}/>
            )}
            </IonCardContent>
        </IonCard>
        </div>
        {state === 'detail' && (
            <BackButton fn={backView} value='header'/>
        )}
    </IonContent>
    </>
    );
}

export default PhysicalTake;