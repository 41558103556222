import HttpClient from '../../services/HttpCilent';
import { returnResponse } from '../../Helper/Helpers';

export const Authenticate = (data) =>{
    return new Promise((resolve, reject) => {
        HttpClient.post(`/api/userValidates`, data).then(resp => {
            console.log("resp", resp)
            if (resp.data.response) {
                sessionStorage.setItem("username", resp.data.data.description);
                sessionStorage.setItem("profileCode", resp.data.data.profileCode);
                sessionStorage.setItem("token", resp.data.data.token);
                sessionStorage.setItem("userCode", resp.data.data.userCode);
            }
            const data = {
                status: resp.data.response,
                messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert]
            };
            resolve(data);
        }).catch(error => {
            console.log("error", error)
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte']
            };
            reject(data);
        })
    })
}

export const SendToken = () =>{
    return new Promise((resolve) => {
        HttpClient.get(`/api/userValidates/sendCodeToMail/edit`).then(response => {
            resolve(returnResponse(response));
        })
    })
}

export const SendTokenValidate = (token) =>{
    return new Promise((resolve) => {
        HttpClient.get(
            `/api/userValidates/getByUserAndCode/edit?two_factor_code=`+token
        ).then(response => {
            resolve(returnResponse(response));
        })
    })
}