import { IonRow, IonCol, IonSelect, IonSelectOption, IonItem, IonLabel } from "@ionic/react";
import { useEffect, useState } from "react";
import styles from "../Css/CustomPaginator.module.scss";

const CustomPaginator = ({ tittle, pags, to, actualPage, fn }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const calculate = () => {
            if (pags !== 0 && to !== 0) {
                const total = pags / parseInt(to);
                let list = [];
                for (let index = 0; index < total; index++) {
                    list.push(index + 1);
                };
                setItems(list);
            }
        };
        calculate();
    }, [pags, to]);

    const printPages = () => {
        const pages = items.map((item) => (
            <IonSelectOption value={item} key={item}>{item}</IonSelectOption>
        ));
        return pages;
    };

    return (
        <div className={styles.field}>
            <IonRow>
                <IonCol size="auto">
                    <IonItem className={styles.item}>
                        <IonLabel className={styles.paginator}>{tittle}</IonLabel>
                        <IonSelect interface="popover" className={styles.paginator} onIonChange={(e) => fn(e.detail.value)} value={actualPage}>
                            {printPages()}
                        </IonSelect>
                    </IonItem>
                </IonCol>
            </IonRow>
        </div>
    );
};

export default CustomPaginator;