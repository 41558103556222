import React, { useEffect, useState } from "react";
import { IonContent, IonCard, IonCardContent, IonLoading } from "@ionic/react";
import styles from "./Css/OrderPos.module.scss";
import CustomHeader from "../../General/Components/CustomHeader";
import BackButton from "../../General/Components/BackButton";
import { helpContent, helpContentDetails } from "./Data/Data";
import List from "./Components/List";
import NewOrEditOrder from "./Components/NewOrEditOrder";
import { getListOrders } from "./Service";

function CarrierRemissionGuide() {
    const [name, setName] = useState("Orden");
    const [state, setState] = useState("header");
    const [documentSelected, setDocumentSelected] = useState(helpContent());
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [total, setTotal] = useState(0);
    const [forPage, setForPage] = useState(0);
    const [actualPage, setActualPage] = useState(1);
    const [type, setType] = useState(0);
    const [orderHeader, setOrderHeader] = useState([]);

    useEffect(() => {
        listOrders();
    }, []);

    function listOrders(page = 1) {
        setActualPage(page);
        setLoading(true);
        getListOrders(page).then((response) => {
            setLoading(false);
            setHeaders(response.headers);
            if (response.status) {
                setDetails(response.data.data);
                setTotal(response.data.total);
                setForPage(response.data.forPage);
            }
        });
    }

    function backView() {
        setState("header");
        setDocumentSelected(helpContent());
        setName('Orden');
        setType(0);
    }

    const newOrEditOrder = () => {
        setType(0);
        setState("newOrder");
        setDocumentSelected(helpContentDetails());
        setName('Nuevo Pedido');
    }

    const viewDetail = (type, order) => {
        setOrderHeader(order);
        setType(type);
        setState("newOrder");
        setName('Orden # ' +order.code);
    }

    const returnOrderHeader = (order) => {
        setOrderHeader(order);
        setType(1);
        setName('Orden # ' +order.code);
    }

    return (
        <>
            <CustomHeader name={name} content={documentSelected} />
            <IonLoading isOpen={loading} message="Cargando..." />
            <IonContent scrollY={false}>
                <div className={styles.center}>
                    <IonCard className={styles.ionCard}>
                        <IonCardContent>
                            {state === "header" && (
                                <List
                                    newOrEditOrder={newOrEditOrder}
                                    headers={headers}
                                    details={details}
                                    total={total}
                                    forPage={forPage}
                                    viewDetail={viewDetail}
                                    listOrders={listOrders}
                                    actualPage={actualPage}
                                />
                            )}
                            {state === "newOrder" && (
                                <NewOrEditOrder
                                    type={type}
                                    order={orderHeader}
                                    returnOrderHeader={returnOrderHeader}
                                />
                            )}
                        </IonCardContent>
                    </IonCard>
                </div>
                {(state === "newOrder") && (
                    <BackButton fn={backView} value="header" />
                )}
            </IonContent>
        </>
    );
}

export default CarrierRemissionGuide;
