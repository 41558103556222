import styles from './Css/Login.module.scss';
import React, { useState } from 'react';
import {
    IonButton, IonIcon, IonSlide, IonContent, IonGrid, IonAlert,
    IonRow, IonCol, IonList, IonItem, IonInput, IonImg, IonLoading, useIonToast
} from '@ionic/react';
import { personCircle, lockClosed, closeCircle } from 'ionicons/icons';
import { Authenticate, SendTokenValidate } from './Services';
import { useHistory } from "react-router-dom";

function Login() {
    const [showAlert, setShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = window.$location;
    const urlBack = window.$urlBack;
    const [present] = useIonToast();
    const history = useHistory();

    const validateBack = () => {
        let status = true;
        if(location === '' || location === undefined || urlBack === '' || urlBack === undefined ){
            status = false;
        }
        return status;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(validateBack()){
            setLoading(true);
            const data = {
                user: e.target.username.value,
                password: e.target.password.value,
                location: location
            };

            Authenticate(data).then((response) => {
                if (response.status) {
                    history.push("/home");
                    //se comenta doble factor de autenticacion
                    /*SendToken().then((response) => {
                        setLoading(false);
                        if(response.status){
                            setShowAlert(true);
                        }else{
                            messageError(response.messages);
                        }
                    });*/
                } else {
                    setLoading(false);
                    messageError(response.messages);
                }
            }).catch((error) => {
                setLoading(false);
                messageError(error.messages);
                console.log("error", error)
            });
        } else {
            messageError(['Comuníquese con soporte y verifiquen que las variables de entorno de la aplicación se encuentren definidas.']); 
        }
    };

    function validateToken(data){
        sessionStorage.getItem('token');
        setLoading(true);
        SendTokenValidate(data[0]).then((response) => {
            if(response.status){
                history.push("/home");
            }else{
                setLoading(false);
                messageError(response.messages);
            }
        });
    }

    function messageError(messages){
        messages.map((message) => (
            present({
                message: message,
                duration: 3000,
                position: 'bottom',
                color: 'secondary',
                icon: closeCircle
            })
        ));
    }

    return (
        <IonContent className={styles.content}>
            <IonGrid className={styles.grid1} >
                <IonRow className={styles.row1}>
                    <IonCol></IonCol>
                </IonRow>
                <IonRow className={styles.row2}>
                    <IonCol></IonCol>
                </IonRow>
            </IonGrid>
            <IonSlide>
                <div className={styles.contaierLogin}>
                    <form onSubmit={handleSubmit}>
                        <IonGrid>
                            <IonRow>
                                <IonCol>
                                    <center>
                                        <IonImg draggable="false" className={styles.sir} src="images/sir.png"></IonImg>
                                    </center>
                                    <h1>Iniciar Sesión</h1>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonList>
                                        <IonItem>
                                            <IonIcon className={styles.ionIcon} icon={personCircle}></IonIcon>
                                            <IonInput name="username" type="text" required placeholder="Usuario"></IonInput>
                                        </IonItem>
                                        <IonItem>
                                            <IonIcon className={styles.ionIcon} icon={lockClosed}></IonIcon>
                                            <IonInput name="password" type="password" required placeholder="Contraseña"></IonInput>
                                        </IonItem>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <IonButton expand="block" type="submit">Ingresar</IonButton>
                                    <IonLoading isOpen={loading} message="Cargando..." />
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </form>
                    <center>
                        <IonImg draggable="false" className={styles.grupoKfc} src="images/grupo_kfc.png"></IonImg>
                    </center>
                </div>
            </IonSlide>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass="my-custom-class"
                header={"Token"}
                inputs={[
                    {
                        type: 'password',
                        placeholder: 'Token'
                    }
                ]}
                buttons={[
                    {
                        text: "Cancelar",
                        role: "cancel",
                    },
                    {
                        text: "Aceptar",
                        handler: (token) => {
                            validateToken(token);
                        }
                    }
                ]}
            />
        </IonContent>
    );
}

export default Login;
