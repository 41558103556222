export const formatClientBack = () => {
    return [
        { rename : 'id_client', name: 'identify'},
        { rename : 'document_type', name: 'typeIdentify'},
        { rename : 'name_client', name: 'name'},
        { rename : 'email_client', name: 'email'},
        { rename : 'phone_client', name: 'phone'},
        { rename : 'address_client', name: 'addres'}
    ];
}

export const formatOrderDetailBack = () => {
    return [
        { rename : 'id_order_header', name: 'orderHeader'},
        { rename : 'id_product', name: 'product'},
        { rename : 'quantity', name: 'quantity'},
        { rename : 'unit_price', name: 'quantity'},
        { rename : 'total', name: 'quantity'},
        { rename : 'tax', name: 'quantity'}
    ];
}