import HttpClient from '../../services/HttpCilent';
import { PhysicalTakeHeaderDetail, PhysicalTakeHeader } from './Data/Data';

export const getPhysicTakeHeaders = (data) => {
    return new Promise((resolve) => {
        let response;
        HttpClient.get('/api/physicTakeHeaders/getPhysicTakeHeadersByRestaurant?'
            + 'status=' + data.status
        ).then(resp => {
            if (resp.data.response) {
                response = {
                    status: resp.data.response,
                    data: resp.data.data,
                    headers: (resp.data.headers.length) ? resp.data.headers : PhysicalTakeHeader()
                };
            } else {
                response = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(response);
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte'],
            };
            resolve(data);
        })
    })
}

export const getPhysicTakeDetails = (data) => {
    return new Promise((resolve) => {
        let response;
        HttpClient.get('/api/physicTakeDetails/getAll?id_physic_take=' + data.physicTakeHeaderCode
            + '&article_name=' + data.nameArtcicle
            + '&article_code=' + data.codeArticle
            + '&area=' + data.area
            + '&page=' + data.page
            + '&limit=' + data.limit
            + '&is_paginated=' + data.isPaginate
        ).then(resp => {
            if (resp.data.response) {
                response = {
                    status: resp.data.response,
                    data: resp.data.data,
                    headers: (resp.data.headers.length) ? resp.data.headers : PhysicalTakeHeaderDetail()
                };
            } else {
                response = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
                };
            }
            resolve(response);
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte'],
            };
            resolve(data);
        })
    })
}

export const updateAmountPhysicalTake = (data) => {
    return new Promise((resolve) => {
        HttpClient.put('/api/physicTakeDetails/' + data.id_artcicle + '?type=basic'
            , data
        ).then(resp => {
            let response = {
                status: resp.data.response,
                messages: (resp.data.messages.length) ? resp.data.messages : [resp.data.alert],
            };
            resolve(response);
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte'],
            };
            resolve(data);
        })
    })
}

    
export const getAreas = () =>{
    return new Promise((resolve) => {
        let data;
        HttpClient.get('/api/areasRestaurants/getAreasByRestaurant').then(resp => {
            if(resp.data.response){
                data = {
                    status: resp.data.response,
                    data: resp.data.data
                };
            } else {
                data = {
                    status: resp.data.response,
                    messages: (resp.data.messages.length)? resp.data.messages : [resp.data.alert],
                    data: []
                };
            }
            resolve(data); 
        }).catch(() => {
            const data = {
                status: false,
                messages: ['Ocurrio un error de comunicacion con el servidor comuniquese con soporte']
            };
            resolve(data);
        })
    })
}